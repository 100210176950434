<template>
  <div>
    <div v-if="tempGraph" id="chartTemp"></div>
    <div v-if="humiGraph" id="chartHumi"></div>
  </div>
</template>

<script>
import moment from "moment";
import Vue from "vue";
import VueFusionCharts from "vue-fusioncharts";
import FusionCharts from "fusioncharts";
import zoomline from "fusioncharts/fusioncharts.zoomline";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

Vue.use(VueFusionCharts, FusionCharts, zoomline, FusionTheme);

export default {
  name: "CombinedChart",
  props: {
    stats: Array,
    tempGraph: Boolean,
    humiGraph: Boolean,
    title: {
      default: "Chart",
      type: String,
    },
  },
  data() {
    return {
      type: "zoomline",
      dataformat: "json",
      dataSourceTemp: "",
      dataSourceHumi: "",
      labels: [],
      temp: [],
      maxTemp: [],
      minTemp: [],
      humi: [],
      minHumi: [],
      maxHumi: [],
      chartTemp: null,
      chartHumi: null,
    };
  },
  computed: {
    chartToRender() {
      return this.tempGraph || this.humiGraph;
    },
  },
  mounted() {
    this.initialiseChart();
  },
  watch: {
    stats: {
      handler: function () {
        this.initialiseChart();
      },
      deep: true,
      immediate: true,
    },
    tempGraph() {
      this.renderChart();
    },
    humiGraph() {
      this.renderChart();
    },
  },
  methods: {
    initialiseChart() {
      this.labels = [];
      this.temp = [];
      this.minTemp = [];
      this.maxTemp = [];
      this.humi = [];
      this.minHumi = [];
      this.maxHumi = [];

      if (this.stats) {
        for (const element of this.stats) {
          if (element.temperature === "" || element.humidity === 0) {
            continue; // Skip this iteration and move to the next one
          }
          let date = element.deviceTimeStamp;
          date = moment(date).format("DD/MM/YY | HH:mm");
          this.labels.push({
            label: date,
          });
          this.temp.push({
            value: element.temperature,
          });
          this.maxTemp.push({
            value: element.deviceSettings.maxTemperature,
          });
          this.minTemp.push({
            value: element.deviceSettings.minTemperature,
          });
          this.humi.push({
            value: element.humidity,
          });
          this.maxHumi.push({
            value: element.deviceSettings.maxHumidity,
          });
          this.minHumi.push({
            value: element.deviceSettings.minHumidity,
          });
        }
      }

      if (this.labels.length > 0) {
        this.dataSourceTemp = {
          chart: {
            caption: "Temperature Chart",
            subcaption: "Temperature over time",
            yaxisname: "Temperature",
            numbersuffix: "°C",
            xaxisname: "Time",
            lineThickness: "3",
            theme: "fusion",
            paletteColors: "#F3C963,#28A84A,#DC3030",
            exportEnabled: "1",
            exportFileName: this.title,
            rotateLabels: "0",
            labelDisplay: "rotate",
          },
          categories: [
            {
              category: this.labels,
            },
          ],
          dataset: [
            {
              seriesName: "Min Temperature",
              data: this.minTemp,
            },
            {
              seriesName: "Ambient Temperature",
              data: this.temp,
            },
            {
              seriesName: "Max Temperature",
              data: this.maxTemp,
            },
          ],
        };
        this.dataSourceHumi = {
          chart: {
            caption: "Humidity Chart",
            subcaption: "Humidity over time",
            yaxisname: "Humidity",
            numbersuffix: "%",
            xaxisname: "Time",
            lineThickness: "3",
            theme: "fusion",
            paletteColors: "#F3C963,#28A84A,#DC3030",
            exportEnabled: "1",
            exportFileName: this.title,
          },
          categories: [
            {
              category: this.labels,
            },
          ],
          dataset: [
            {
              seriesName: "Min Humidity",
              data: this.minHumi,
            },
            {
              seriesName: "Ambient Humidity",
              data: this.humi,
            },
            {
              seriesName: "Max Humidity",
              data: this.maxHumi,
            },
          ],
        };
      } else {
        this.dataSourceTemp = this.dataSourceHumi = "";
      }
      this.renderChart();
    },
    renderChart() {
      if (this.chartTemp) {
        this.chartTemp.dispose();
      }
      if (this.chartHumi) {
        this.chartHumi.dispose();
      }
      if (this.tempGraph) {
        // Render temperature chart
        this.chartTemp = new FusionCharts({
          type: "zoomline",
          width: "100%",
          height: "470",
          renderAt: "chartTemp",
          dataFormat: "json",
          dataSource: this.dataSourceTemp,
        });
        this.$nextTick(() => {
          this.chartTemp.render();
        });
      }
      if (this.humiGraph) {
        // Render humidity chart
        this.chartHumi = new FusionCharts({
          type: "zoomline",
          width: "100%",
          height: "470",
          renderAt: "chartHumi",
          dataFormat: "json",
          dataSource: this.dataSourceHumi,
        });
        this.$nextTick(() => {
          this.chartHumi.render();
        });
      }
    },
  },
};
</script>
<style scoped src="../assets/style.css"></style>