<template>
  <div id="map-container" class="map-container">
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      loader="bars"
    ></loading>
  </div>
</template>

<script>
import loadGoogleMapsApi from "load-google-maps-api";
import Loading from "vue-loading-overlay";
export default {
  name: "DeviceRoutes2",
  data() {
    return {
      map: null,
      polygonCoords: [],
      polygonShape: null,
      newMap: null,
      newShape_: null,
      drawManager: null,
      isLoading: false,
      fullPage: true,
    };
  },
  methods: {
    initializeMap() {
      const mapZoom = 7;
      const { google } = window;
      const mapOptions = {
        zoom: mapZoom,
        center: { lat: 6.5244, lng: 3.3792 },
        mapTypeControl: true,
        streetViewControl: false,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_LEFT,
        },
      };

      const renderOptions = {
        markerOptions: {
          optimized: false,
        },
      };

      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer(
        renderOptions
      );
      this.map = new google.maps.Map(
        document.getElementById("map-container"),
        mapOptions
      );
      directionsRenderer.setMap(this.map);
      this.calculateAndDisplayRoute(directionsService, directionsRenderer);
    },
    calculateAndDisplayRoute(directionsService, directionsRenderer) {
      const { google } = window;

      const { start, end, waypoints } = this.mapPoints;

      directionsService
        .route({
          origin: start,
          destination: end,
          waypoints: waypoints,
          optimizeWaypoints: true,
          travelMode: google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          directionsRenderer.setDirections(response);
        })
        .catch((e) => {
          //   window.alert("Directions request failed due to " + status);
          console.log(e, "e");
          // this.$emit("no-direction");
        });
    },
  },
  mounted() {
    loadGoogleMapsApi({
      key: process.env.VUE_APP_GOOGLE_API_KEY,
      libraries: ["places", "drawing", "geometry"],
    }).then(async () => {
      this.initializeMap();
    });
  },
  props: {
    stats: {
      type: Array,
      default: () => [],
    },
    mapPoints: Object,
  },
  components: {
    Loading,
  },
  watch: {
    stats: {
      handler: function() {
        this.initializeMap();
      },
      deep: true,
    },
    mapPoints: {
      handler: function() {
        this.initializeMap();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.map-container {
  height: 100%;
}
</style>