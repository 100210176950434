<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#213E7A" fill-opacity="0.15" />
    <circle
      cx="12.3431"
      cy="12.3412"
      r="4.48571"
      stroke="white"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: "StepperUncheck",
};
</script>

<style></style>
