var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row my-4"},[(_vm.data)?_c('div',{staticClass:"col-sm-12 col-md-5 data-table-rows-per-page"},[_c('label',[_vm._v("Rows per Page "),_c('select',{staticClass:"form-control mx-1",attrs:{"name":"entries"},domProps:{"value":_vm.sizeperpage},on:{"change":function($event){return _vm.$emit('onchange', $event)}}},[_c('option',{attrs:{"value":"5"}},[_vm._v("5")]),_c('option',{attrs:{"value":"10"}},[_vm._v("10")]),_c('option',{attrs:{"value":"20"}},[_vm._v("20")]),_c('option',{attrs:{"value":"40"}},[_vm._v("40")]),_c('option',{attrs:{"value":"100"}},[_vm._v("100")])])])]):_vm._e(),_c('div',{staticClass:"col-sm-12 col-md-7"},[_c('div',{staticClass:"dataTables_paginate paging_simple_numbers"},[_c('ul',{staticClass:"pagination"},[_c('span',{staticClass:"d-flex align-items-center justify-content-center",class:{
            disabled: _vm.pageNumber <= 1,
            'cursor-pointer': _vm.pageNumber > 1,
          },on:{"click":function($event){return _vm.$emit('prevPage')}}},[_c('PrevIcon')],1),_c('li',{staticClass:"paginate_button page-item",class:{ active: _vm.pageNumber == 1 },on:{"click":function($event){return _vm.$emit('goToFirstPage')}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v("1")])]),(_vm.pageCount === 3)?_c('li',{staticClass:"paginate_button page-item",class:{ active: _vm.pageNumber == 2 },on:{"click":function($event){return _vm.$emit('handlePageChange', 2)}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v("2")])]):_vm._e(),(_vm.pageCount > 3)?_c('div',{staticStyle:{"display":"inherit","column-gap":"8px"}},_vm._l((_vm.pages),function(page){return _c('li',{key:page.name,staticClass:"paginate_button page-item",class:{ active: page.isDisabled },on:{"click":function($event){return _vm.$emit('handlePageChange', page.name)}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v(_vm._s(page.name))])])}),0):_vm._e(),(_vm.pageCount > 1)?_c('li',{staticClass:"paginate_button page-item",class:{ active: _vm.pageNumber == _vm.pageCount },on:{"click":function($event){return _vm.$emit('goToLastPage')}}},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.pageCount))])]):_vm._e(),_c('span',{staticClass:"d-flex align-items-center justify-content-center",class:{
            disabled: _vm.pageNumber >= _vm.pageCount,
            'cursor-pointer': _vm.pageNumber < _vm.pageCount,
          },on:{"click":function($event){return _vm.$emit('nextPage')}}},[_c('NextIcon')],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }