<template>
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.62856 5.85383C9.2857 7.56811 7.99313 9.18228 6.17942 9.54297C5.29484 9.71913 4.37723 9.61172 3.55725 9.23604C2.73726 8.86035 2.05671 8.23554 1.61249 7.45057C1.16826 6.6656 0.983016 5.76049 1.08312 4.86412C1.18323 3.96774 1.56359 3.1258 2.17004 2.45817C3.41393 1.08811 5.51427 0.710969 7.22856 1.39668"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.80078 5.16864L5.51507 6.88292L9.62935 2.42578"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "StepperCheck",
};
</script>

<style></style>
