<template>
  <nav class="bg-primary flex items-center justify-between px-4 md:px-10 py-3">
    <div class="flex items-center">
      <img src="./../assets/Figorr.svg" alt="Figorr" style="width: 100px" />
    </div>
    <!-- <div class="flex items-center gap-x-3 cursor-pointer" @click="logout">
      <img src="./../assets/Rectangle.svg" alt="Figorr" />
      <span class="text-white">Logout</span>
    </div> -->
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  methods: {
    logout() {
      this.$emit("logout");
    },
  },
};
</script>

<style scoped>
.bg-primary {
  background-color: #213e7a !important;
}
.flex {
  display: flex !important;
}
.items-center {
  align-items: center !important;
}
.justify-between {
  justify-content: space-between !important;
}
.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.md\:px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.gap-x-3 {
  column-gap: 0.75rem !important;
}
.h-8 {
  height: 2rem !important;
}
.w-8 {
  width: 2rem !important;
}
.text-white {
  color: #fff !important;
  font-weight: 600 !important;
  font-size: large !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
</style>
