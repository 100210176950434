export default {
  data() {
    return {
      pageNumber: 1,
      sizeperpage: 10,
      maxVisibleButtons: 3,
      searchQuery: "",
      sortKey: "",
      sortOrders: {},
    };
  },
  methods: {
    onPageSelect() {
      if (this.searchQuery) {
        this.$emit("doSearch", this.params, this.searchQuery);
      } else this.$emit("pageChange", this.params);
    },
    handlePageChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.onPageSelect();
    },
    nextPage() {
      if (this.pageNumber < this.pageCount) {
        this.pageNumber++;
        this.onPageSelect();
      }
    },
    prevPage() {
      if (this.pageNumber > 1) {
        this.pageNumber--;
        this.onPageSelect();
      }
    },
    sortBy: function(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
    },
    goToFirstPage() {
      this.pageNumber = 1;
      this.onPageSelect();
    },
    goToLastPage() {
      this.pageNumber = this.pageCount;
      this.onPageSelect();
    },
    debouncedSearch: (function() {
      // Declare a timer variable to store the setTimeout return value
      let timer = null;

      // Return a function that will be called after the specified delay
      return function() {
        // Clear the previous timer
        clearTimeout(timer);

        // Start a new timer to call the search function after the specified delay
        timer = setTimeout(() => {
          // Send search request to the server
          // Update searchResults with the response
          this.doSearch();
        }, 500);
      };
    })(),
    doSearch() {
      this.pageNumber = 1;
      // this.$emit("doSearch", this.params, this.searchQuery);
      this.onPageSelect();
    },
    onchange({ target: { value } }) {
      this.sizeperpage = +value;
    },
    handleSearch({ target: { value } }) {
      this.searchQuery = value;
    },
  },
  created() {
    let sortOrders = {};
    this.tableColumns.forEach(function(key) {
      sortOrders[key[1]] = 1;
    });
    this.sortOrders = sortOrders;
  },
  computed: {
    currentPage() {
      return this.paginationMeta?.currentPage || this.pageNumber;
    },
    pageCount() {
      return this.paginationMeta?.totalPages;
    },
    totalItems() {
      return this.paginationMeta.totalItems;
    },
    params() {
      return {
        limit: this.sizeperpage,
        page: this.pageNumber,
      };
    },
    startPage() {
      // When on the first page
      if (this.pageNumber === 1) {
        return 2;
      }
      // When on the last page
      if (this.pageNumber === this.pageCount) {
        return this.pageCount - this.maxVisibleButtons;
      }
      // When in between
      return this.pageNumber - 1;
    },
    pages() {
      const range = [];

      if (this.startPage === 1) {
        for (
          let i = this.startPage + 1;
          i <=
          Math.min(this.startPage + this.maxVisibleButtons - 1, this.pageCount);
          i++
        ) {
          range.push({
            name: i,
            isDisabled: i === this.pageNumber,
          });
        }
      } else if (
        this.startPage ===
        this.pageCount - this.maxVisibleButtons + 1
      ) {
        for (
          let i = this.startPage;
          i <=
          Math.min(
            this.startPage + this.maxVisibleButtons - 1,
            this.pageCount - 1
          );
          i++
        ) {
          range.push({
            name: i,
            isDisabled: i === this.pageNumber,
          });
        }
      } else {
        for (
          let i = this.startPage;
          i <=
          Math.min(this.startPage + this.maxVisibleButtons - 1, this.pageCount);
          i++
        ) {
          range.push({
            name: i,
            isDisabled: i === this.pageNumber,
          });
        }
      }

      return range;
    },
    paginatedData() {
      let data = this.dataTable;
      let sortKey = this.sortKey;
      let order = this.sortOrders[sortKey] || 1;
      if (sortKey) {
        data = data.slice().sort(function(a, b) {
          a = a[sortKey];
          b = b[sortKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    },
  },
  watch: {
    paginatedData: {
      handler() {
        if (this.paginatedData.length === 0) {
          this.dataCheck = false;
        } else this.dataCheck = true;
      },
    },
    sizeperpage() {
      this.pageNumber = 1;
      this.$emit("pageChange", this.params);
    },

    searchQuery() {
      // Call the debouncedSearch method
      this.debouncedSearch();
    },
  },
};
