<template>
  <main class="flex flex-col">
    <router-view />
  </main>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
</style>
