<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M6.35689 3.01165L11.4863 7.6391C11.7059 7.82734 11.7059 8.17244 11.4863 8.36067L6.35689 12.9881C6.02747 13.2862 5.49414 13.0666 5.49414 12.6273L5.49414 3.37244C5.49414 2.93322 6.02747 2.71361 6.35689 3.01165Z"
      fill="#78AADA"
    />
  </svg>
</template>

<script>
export default {
  name: "PaginationNext",
};
</script>

<style></style>
