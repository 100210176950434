<template>
  <div class="dataTables_filter" id="zero_config_filter">
    <div
      :class="`position-relative ${containerClass}`"
      style="width: fit-content; margin-left: auto"
    >
      <input
        type="search"
        :value="searchQuery"
        class="form-control"
        :placeholder="placeholder"
        style="padding-left: 33px"
        @input="$emit('handleSearch', $event)"
        v-bind="$attrs"
      />
      <i
        class="fas fa-search fa-lg position-absolute"
        style="top: 15px; color: #b7c3cd"
        :style="left"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableSearch",
  props: {
    searchQuery: String,
    placeholder: {
      type: String,
      default: function () {
        return "Search";
      },
    },
    left: {
      type: String,
      default: "left: 10px;",
    },
    containerClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped src="../assets/style.css"></style>
