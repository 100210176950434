<template>
  <div class="row my-4">
    <div v-if="data" class="col-sm-12 col-md-5 data-table-rows-per-page">
      <label
        >Rows per Page
        <select
          :value="sizeperpage"
          name="entries"
          class="form-control mx-1"
          @change="$emit('onchange', $event)"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="40">40</option>
          <option value="100">100</option>
        </select>
      </label>
    </div>
    <div class="col-sm-12 col-md-7">
      <div class="dataTables_paginate paging_simple_numbers">
        <ul class="pagination">
          <span
            class="d-flex align-items-center justify-content-center"
            :class="{
              disabled: pageNumber <= 1,
              'cursor-pointer': pageNumber > 1,
            }"
            @click="$emit('prevPage')"
          >
            <PrevIcon />
          </span>

          <li
            class="paginate_button page-item"
            :class="{ active: pageNumber == 1 }"
            @click="$emit('goToFirstPage')"
          >
            <a href="#" class="page-link">1</a>
          </li>

          <li
            class="paginate_button page-item"
            :class="{ active: pageNumber == 2 }"
            v-if="pageCount === 3"
            @click="$emit('handlePageChange', 2)"
          >
            <a href="#" class="page-link">2</a>
          </li>

          <div v-if="pageCount > 3" style="display: inherit; column-gap: 8px">
            <li
              class="paginate_button page-item"
              :class="{ active: page.isDisabled }"
              v-for="page in pages"
              :key="page.name"
              @click="$emit('handlePageChange', page.name)"
            >
              <a href="#" class="page-link">{{ page.name }}</a>
            </li>
          </div>

          <!-- <li
            class="paginate_button page-item next"
            :class="{ disabled: pageNumber >= pageCount }"
            @click="$emit('nextPage')"
          >
            <a href="#" class="page-link">{{ $t("next") }}</a>
          </li> -->

          <li
            class="paginate_button page-item"
            :class="{ active: pageNumber == pageCount }"
            v-if="pageCount > 1"
            @click="$emit('goToLastPage')"
          >
            <a href="#" class="page-link">{{ pageCount }}</a>
          </li>

          <span
            class="d-flex align-items-center justify-content-center"
            :class="{
              disabled: pageNumber >= pageCount,
              'cursor-pointer': pageNumber < pageCount,
            }"
            @click="$emit('nextPage')"
          >
            <NextIcon />
          </span>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import PrevIcon from "./IconsSvg/PaginationBack.vue";
import NextIcon from "./IconsSvg/PaginationNext.vue";

export default {
  name: "PaginationNew",
  props: {
    pageCount: Number,
    data: Array,
    sizeperpage: Number,
    pageNumber: Number,
    pages: Array,
  },
  components: {
    PrevIcon,
    NextIcon,
  },
};
</script>

<style scoped src="../assets/style.css"></style>
