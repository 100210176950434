<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      loader="bars"
    ></loading>
    <Navbar @logout="logout" />
    <div class="page-wrapper p-4" v-if="trackingDetails.company">
      <div class="container-fluid mt-0">
        <h1 class="font-24 font-weight-bold mb-1">Track Order</h1>
        <!-- <p>
          <span class="gray4 title-width">Tracking Code: </span>
          <span>{{ selectedDropoff.trackingCode }}</span>
        </p> -->
        <div class="w-100 mt-5 card p-3">
          <div class="row mx-0 mb-4">
            <div class="col-12 col-md-5">
              <div class="row col-gap-2 mx-0" v-if="selectedDropoff">
                <div class="select" style="display: grid">
                  <label for="device-select">Select Device</label>
                  <select v-model="currentIndex">
                    <option
                      v-for="(item, index) in selectedDropoff.items"
                      :key="index"
                      :value="index"
                    >
                      {{ item.dataLoggerDetails.settings.name }}
                    </option>
                  </select>
                </div>
                <div class="">
                  <div
                    class="d-flex col-gap-1 align-items-center mb-2"
                    v-if="selectedDevice"
                  >
                    <span class="gray4 title-width">Device Tag</span>
                    <span>{{
                      selectedDevice.dataLoggerDetails.deviceTag
                    }}</span>
                  </div>
                  <div
                    class="d-flex col-gap-1 align-items-center mb-2"
                    v-if="selectedDevice"
                  >
                    <span class="gray4 title-width">Device Name</span>
                    <span>{{
                      selectedDevice.dataLoggerDetails.settings.name
                    }}</span>
                  </div>
                  <div class="d-flex col-gap-1 align-items-center">
                    <span class="gray4 title-width">IMEI</span>
                    <span>{{ selectedDevice.dataLoggerDetails.imei }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-7">
              <div class="stepper-container-width">
                <Stepper :step="step" :labels="labels" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-7">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-space-around col-md-10 col-lg-6 mx-auto"
                  >
                    <div class="btn-container">
                      <button
                        @click="
                          tempGraph = true;
                          humiGraph = false;
                        "
                        :disabled="tempGraph == true"
                        id="graphselect"
                      >
                        Temperature
                      </button>
                    </div>
                    <div class="btn-container">
                      <button
                        @click="
                          tempGraph = false;
                          humiGraph = true;
                        "
                        :disabled="tempGraph == false"
                        id="graphselect"
                      >
                        Humidity
                      </button>
                    </div>
                  </div>
                  <div>
                    <CombinedChart
                      :tempGraph="tempGraph"
                      :humiGraph="humiGraph"
                      :stats="trackHistoryChart"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div
                class="card"
                ref="mapContent"
                id="mapCont"
                style="height: 100%; min-height: 500px"
              >
                <div class="card-body" id="map-image">
                  <DeviceRoutes2 :mapPoints="trackHistoryMapPoints" />
                </div>
              </div>
            </div>
          </div>

          <div class="card-body" style="max-height: 35rem; overflow-y: auto">
            <CombinedTable
              :stats="trackingHistory"
              :type="
                selectedDropoff.items[
                  currentIndex
                ].dataLoggerDetails.type.toLowerCase()
              "
              :paginationMeta="trackHistoryMeta"
              @pageChange="pageChange"
              :showSearch="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CombinedChart from "@/components/CombinedChart.vue";
import DeviceRoutes2 from "@/components/DeviceRoutes2.vue";
import CombinedTable from "@/components/CombinedTable.vue";
import Stepper from "@/components/Stepper.vue";
import Navbar from "../components/Navbar.vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "TrackerPage",
  components: {
    CombinedChart,
    DeviceRoutes2,
    CombinedTable,
    Stepper,
    Navbar,
    Loading,
  },
  data() {
    return {
      tempGraph: true,
      humiGraph: false,
      labels: ["Order picked up", "Order on the way", "Order arrived"],
      step: null,
      isLoading: true,
      fullPage: true,
      trackingCode: "",
      trackingDetails: {},
      trackHistoryChart: [],
      trackHistoryMapPoints: {},
      trackingHistory: [],
      trackHistoryMeta: {},
      params: {
        limit: 10,
        page: 1,
      },
      currentDevice: {},
      currentIndex: 0,
      selectedDropoff: null,
    };
  },
  watch: {
    trackingDetails: {
      handler: function (val) {
        switch (val.status) {
          case "new":
            this.step = 1;
            break;
          case "in_progress":
            this.step = 2;
            break;
          case "completed":
            this.step = 3;
            break;
          default:
            break;
        }
      },
      immediate: true,
      deep: true,
    },
    currentIndex: {
      handler: function () {
        this.isLoading = true;
        const dataLogger = this.selectedDevice?.dataLogger;
        this.fetchHistory(
          this.$route.params.trackingCode,
          this.$route.params.unicode,
          {
            ...this.params,
            dataLogger,
          }
        );
        this.fetchHistoryChart(
          this.$route.params.trackingCode,
          this.$route.params.unicode,
          dataLogger
        );
        this.fetchHistoryMapPoints(
          this.$route.params.trackingCode,
          this.$route.params.unicode,
          dataLogger
        );
      },
      immediate: true,
    },
  },
  created() {
    this.handletrackingCode(
      this.$route.params.trackingCode,
      this.$route.params.unicode,
      this.params
    );
  },
  methods: {
    async fetchOrders(trackingCode, uniqueCode) {
      const baseUrl = process.env.VUE_APP_BASE_URI;
      return await axios
        .get(`${baseUrl}/orders/company/${uniqueCode}/track/${trackingCode}`)
        .then((res) => {
          this.isLoading = false;
          this.trackingDetails = res.data.payload;

          // Find the dropoff that matches the tracking code
          this.selectedDropoff = this.trackingDetails.dropoffs.find(
            (dropoff) => dropoff.trackingCode === trackingCode
          );

          if (!this.selectedDropoff) {
            this.$router.push({ name: "NotFound" });
          }

          return res;
        })
        .catch(() => {
          this.$router.push({ name: "NotFound" });
          this.isLoading = false;
        });
    },
    async fetchHistory(trackingCode, uniqueCode, params) {
      const baseUrl = process.env.VUE_APP_BASE_URI;
      await axios
        .get(
          `${baseUrl}/orders/company/${uniqueCode}/track/${trackingCode}/history`,
          { params }
        )
        .then((res) => {
          const { meta, data } = res.data.payload;
          this.trackingHistory = data;
          this.trackHistoryMeta = meta;

          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err, "error");
          this.isLoading = false;
        });
    },
    async fetchHistoryChart(trackingCode, uniqueCode, dataLogger) {
      const baseUrl = process.env.VUE_APP_BASE_URI;
      await axios
        .get(
          `${baseUrl}/orders/company/${uniqueCode}/track/${trackingCode}/history/chart`,
          { params: { dataLogger } }
        )
        .then((res) => {
          this.trackHistoryChart = res.data.payload;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err, "error");
          this.isLoading = false;
        });
    },
    async fetchHistoryMapPoints(trackingCode, uniqueCode, dataLogger) {
      const baseUrl = process.env.VUE_APP_BASE_URI;
      await axios
        .get(
          `${baseUrl}/orders/company/${uniqueCode}/track/${trackingCode}/history/map-points`,
          { params: { dataLogger } }
        )
        .then((res) => {
          this.trackHistoryMapPoints = res.data.payload;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err, "error");
          this.isLoading = false;
        });
    },
    handletrackingCode(trackingCode, uniqueCode, params) {
      this.isLoading = true;
      this.params = params;
      this.fetchOrders(trackingCode, uniqueCode).then((res) => {
        const dataLogger =
          res?.data?.payload?.dropoffs[0]?.items[this.currentIndex]?.dataLogger;

        this.fetchHistory(trackingCode, uniqueCode, {
          ...params,
          dataLogger,
        });
        this.fetchHistoryChart(trackingCode, uniqueCode, dataLogger);
        this.fetchHistoryMapPoints(trackingCode, uniqueCode, dataLogger);
      });
      // this.fetchHistory(trackingCode, uniqueCode, params);
      // this.fetchHistoryChart(trackingCode, uniqueCode);
      // this.fetchHistoryMapPoints(trackingCode, uniqueCode);
    },
    pageChange(params) {
      this.isLoading = true;
      this.params = params;
      this.handletrackingCode(
        this.$route.params.trackingCode,
        this.$route.params.unicode,
        params
      );
    },
    logout() {
      this.trackingDetails = {};
      this.trackHistoryChart = [];
      this.trackHistoryMapPoints = {};
      this.trackingHistory = [];
      this.trackHistoryMeta = {};
      this.$router.push(`/${this.$route.params.unicode}`);
    },
  },
  computed: {
    selectedDevice() {
      return this.selectedDropoff?.items[this.currentIndex] || {};
    },
  },
};
</script>

<style scoped src="../assets/style.css"></style>
<style scoped>
.title-width {
  width: 90px;
}

.btn-container {
  display: flex;
  justify-content: center;
  width: 50%;
}
#graphselect {
  background-color: inherit;
  border: none;
  color: #213e7a;
  padding: 7px 25px;
  border-radius: 30px;
  outline: none;
}
#graphselect:hover {
  background-color: #213e7a;
  color: white;
}
#graphselect:disabled,
#graphselect[disabled] {
  background-color: #dee2eb;
  color: #213e7a;
}

.stepper-container-width {
  width: 75%;
  margin-left: auto;
}

@media (max-width: 768px) {
  .stepper-container-width {
    width: 100%;
  }
  .p-4 {
    padding: 0.5rem !important;
  }
}
</style>
