<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M10.0552 12.9883L4.92581 8.36089C4.7062 8.17266 4.7062 7.82756 4.92581 7.63933L10.0552 3.01188C10.3846 2.71384 10.918 2.93344 10.918 3.37266L10.918 12.6276C10.918 13.0668 10.3846 13.2864 10.0552 12.9883Z"
      fill="#78AADA"
    />
  </svg>
</template>

<script>
export default {
  name: "PaginationBack",
};
</script>

<style></style>
