<template>
  <div class="stepper-wrapper">
    <div
      class="stepper d-flex justify-content-between align-items-center px-3 mb-2"
    >
      <div
        v-for="(_, index) in labels"
        :key="`${index}`"
        class="d-flex align-items-center"
        :class="{ 'w-100': index < labels.length - 1 }"
      >
        <span
          class="rounded-pill d-flex justify-content-center align-items-center active-container"
          v-if="step === index + 1 || step > index + 1"
        >
          <stepper-check />
        </span>
        <!-- <span class="done" v-else-if="step > index + 1">
          <stepper-check />
        </span> -->
        <stepper-uncheck v-else />
        <div
          class="stepper-line"
          :style="step > index + 1 && 'background-color:#213e7a'"
          v-if="index < labels.length - 1"
        ></div>
      </div>
    </div>

    <div class="stepper d-flex justify-content-between align-items-center px-3">
      <!-- <span v-for="label in labels" :key="label">
        <stepper-check />
      </span> -->
      <span class="">
        <parcel-icon />
      </span>
      <span
        :class="{
          'status-icon-check': step >= 2,
          'status-icon-uncheck': step < 2,
        }"
      >
        <van-icon />
      </span>
      <span
        :class="{
          'status-icon-check': step >= 3,
          'status-icon-uncheck': step < 3,
        }"
      >
        <home />
      </span>
    </div>
    <div class="stepper d-flex justify-content-between align-items-center">
      <p
        v-for="(label, index) in labels"
        :key="label"
        :class="
          step >= index + 1 ? 'status-check-label' : 'status-uncheck-label'
        "
      >
        {{ label }}
      </p>
    </div>
  </div>
</template>

<script>
import ParcelIcon from "./IconsSvg/ParcelIcon.vue";
import VanIcon from "./IconsSvg/VanIcon.vue";
import Home from "./IconsSvg/Home.vue";
// import ParcelIcon from "./IconsSvg/ParcelIcon.vue";
import StepperCheck from "./IconsSvg/StepperCheck.vue";
import StepperUncheck from "./IconsSvg/StepperUncheck.vue";
// import VanIcon from './IconsSvg/VanIcon.vue';
// import StepperCheck from "./Iconsvg/StepperCheck.vue";
// import StepperCheck from "./Iconsvg/StepperCheck.vue";
// import StepperUncheck from "./Iconsvg/StepperUncheck.vue";

export default {
  components: { StepperCheck, StepperUncheck, ParcelIcon, VanIcon, Home },
  name: "Stepper",
  props: {
    step: Number,
    labels: Array,
  },
};
</script>

<style scoped src="../assets/style.css"></style>
<style scoped>
.stepper-line {
  height: 5px;
  /* width: 50%; */
  background-color: #eff0f7;
  width: calc(100% - 1rem);
}

.active-container {
  height: 24px;
  width: 24px;
  background-color: #213e7a;
}

/* @media (min-width: 768px) {
  .stepper-wrapper {
    padding:0 1rem;
  }
}

@media (min-width: 1600px) {
    .stepper-wrapper {
    padding:0 2rem;
  }
} */

.status-icon-uncheck svg {
  fill: #8693a5 !important;
}
.status-icon-check svg {
  fill: #404a57 !important;
}

.status-uncheck-label {
  color: #8693a5;
}

.status-check-label {
  color: #404a57;
}
</style>
