<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 6.5L16 8.5M16 8.5L15.5 8.75L11 11M16 8.5V12M16 8.5L6.5 3.5M11 11L2 6.5M11 11V20.5M14.578 2.382L16.578 3.432C18.729 4.561 19.805 5.125 20.403 6.14C21 7.154 21 8.417 21 10.94V11.057C21 13.582 21 14.845 20.403 15.859C19.805 16.874 18.729 17.439 16.578 18.568L14.578 19.617C12.822 20.539 11.944 21 11 21C10.056 21 9.178 20.54 7.422 19.618L5.422 18.568C3.271 17.439 2.195 16.875 1.597 15.86C1 14.846 1 13.583 1 11.06V10.943C1 8.418 1 7.155 1.597 6.141C2.195 5.126 3.271 4.561 5.422 3.433L7.422 2.383C9.178 1.461 10.056 1 11 1C11.944 1 12.822 1.46 14.578 2.382Z"
      stroke="#404A57"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
    name: "ParcelIcon",
};
</script>

<style></style>
