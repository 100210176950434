<template>
  <div class="table-responsive">
    <div
      v-if="stats"
      id="zero_config_wrapper"
      class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
    >
      <div class="row" v-if="showSearch">
        <div class="col-sm-12 col-md-12">
          <TableSearch :left="'left:18px'" @handleSearch="handleSearch" />
        </div>
      </div>
      <div class="row" style="max-height: 22rem; overflow: auto">
        <div class="col-sm-12">
          <table id="zero_config" class="table">
            <thead class="data-table-bg">
              <tr>
                <th
                  :key="index"
                  v-for="(column, index) in tableColumns"
                  @click="sortBy(column[1])"
                  style="cursor: pointer; text-align: left"
                >
                  {{ column[0] }}
                  <span
                    class="arrow"
                    :class="sortOrders[column[1]] > 0 ? 'asc' : 'dsc'"
                  ></span>
                </th>
                <th>View on Map</th>
              </tr>
            </thead>
            <tbody v-if="this.stats.length > 0">
              <tr
                :key="index"
                v-for="(data, index) in paginatedData"
                style="text-align: left"
                :class="{
                  'data-table-bg': index % 2 !== 0,
                }"
              >
                <td>{{ data.date }}</td>
                <td v-if="type !== 'loctrack'">{{ data.column1 }}</td>
                <td v-if="type == 'mote' || type == 'gps-vt' || type == 'lora'">
                  {{ data.column2 }}
                </td>
                <td>{{ data.column3 }}</td>
                <td v-if="type !== 'loctrack'">{{ data.column4 }}</td>
                <td v-if="type !== 'loctrack'">{{ data.column5 }}</td>
                <td v-if="type == 'mote' || type == 'gps-vt' || type == 'lora'">
                  {{ data.column6 }}
                </td>
                <td v-if="type == 'mote' || type == 'gps-vt' || type == 'lora'">
                  {{ data.column7 }}
                </td>
                <!-- <td
                  v-if="
                    (type === 'loctrack' ||
                      type == 'mote' ||
                      type == 'gps-vt') &&
                      data.isValidLocation
                  "
                >
                  {{ data.lat.toFixed(4) }}
                </td>
                <td
                  v-if="
                    (type === 'loctrack' ||
                      type == 'mote' ||
                      type == 'gps-vt') &&
                      data.isValidLocation
                  "
                >
                  {{ data.lng.toFixed(4) }}
                </td> -->
                <td>
                  <button
                    @click.prevent="viewonMap(data.lat, data.lng)"
                    class="btn btn-primary"
                    v-if="data.isValidLocation"
                  >
                    View
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else align="center">
              <tr align="center">
                <td colspan="8">
                  No Temperature/Humidity data available for this period
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PaginationNew
        :pageCount="pageCount"
        :data="paginatedData"
        :sizeperpage="sizeperpage"
        :pageNumber="pageNumber"
        :pages="pages"
        @goToLastPage="goToLastPage"
        @nextPage="nextPage"
        @handlePageChange="handlePageChange"
        @goToFirstPage="goToFirstPage"
        @prevPage="prevPage"
        @onchange="onchange"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PaginationNew from "./PaginationNew.vue";
import TableSearch from "./TableSearch.vue";
import tableMixin from "../mixins/tableMixinWithBackend";

export default {
  name: "Table",
  mixins: [tableMixin],
  props: {
    stats: Array,
    type: String,
    paginationMeta: Object,
    isFilter: Boolean,
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableColumns: [],
      dataTable: [],
    };
  },
  mounted() {
    this.initialiseTable();
  },
  watch: {
    stats: {
      handler: function () {
        this.dataTable = [];
        this.initialiseTable();
      },
      deep: true,
    },
    isFilter(update) {
      if (update) {
        this.pageNumber = 1;
        this.$emit("pageChange", this.params);
      }
    },
  },
  methods: {
    viewonMap(lat, lng) {
      window.open(`https://google.com/maps/search/${lat},${lng}`, "_blank");
    },
    initialiseTable() {
      this.tableColumns = [
        ["date", "date"],
        ["Temperature (°C)", "column1"],
        ["Humidity (%)", "column2"],
        ["Battery (%)", "column3"],
        ["Min Temperature (°C)", "column4"],
        ["Max Temperature (°C)", "column5"],
        ["Min Humidity (%)", "column6"],
        ["Max Humidity (%)", "column7"],
      ];
      if (
        this.type !== "mote" &&
        this.type !== "gps-vt" &&
        this.type !== "lora"
      ) {
        this.tableColumns.splice(2, 1);
        this.tableColumns.splice(5, 1);
        this.tableColumns.splice(5, 1);
      }

      if (this.stats) {
        for (let i = 0; i < this.stats.length; i++) {
          let date = this.stats[i]?.deviceTimeStamp;
          const localTime = moment.utc(date).local();
          date = localTime.format("MMM Do YYYY, hh:mm a");
          this.dataTable.push({
            column1: this.stats[i]?.temperature?.toFixed(2),
            column2: this.stats[i]?.humidity?.toFixed(2),
            column3: this.stats[i].battery,
            column4: this.stats[i].deviceSettings.minTemperature,
            column5: this.stats[i].deviceSettings.maxTemperature,
            column6: this.stats[i].deviceSettings.minHumidity,
            column7: this.stats[i].deviceSettings.maxHumidity,
            dateOrig: this.stats[i].createdAt,
            date: date,
            lat: this.stats[i].latitude,
            lng: this.stats[i].longitude,
            isValidLocation: this.stats[i].validLocation,
          });
        }
      }
    },
  },
  components: { TableSearch, PaginationNew },
};
</script>

<style scoped src="../assets/style.css"></style>
<style scoped>
th,
td {
  white-space: nowrap;
}
th.active {
  color: #fff;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 8px solid #000000;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #000000;
}
</style>
