<template>
  <div class="main-wrapper">
    <div class="error-box">
      <div class="error-body text-center">
        <h1 class="error-title text-primary">404</h1>
        <h3 class="text-uppercase error-subtitle">{{ errorMsg }}!</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
      errorMsg: "Order does not exist",
    };
  },
};
</script>
<style scoped src="../assets/style.css"></style>
