import Vue from "vue";
import Router from "vue-router";
// import LoginPage from "@/pages/ImeiAuth.vue";
import NotFound from "@/pages/NotFound.vue"; // Component for 404 page
import TrackerPage from "../pages/TrackerPage.vue";

Vue.use(Router);

const routes = [
  // {
  //   path: "/:unicode",
  //   name: "LoginPage",
  //   component: LoginPage,
  // },
  {
    path: "/:unicode/:trackingCode",
    name: "TrackerPage",
    component: TrackerPage,
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new Router({
  mode: "history",
  routes,
});

export default router;
